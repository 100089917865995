import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Table } from "react-bootstrap";
import { db } from "../../../firebase";
import io from "socket.io-client";
import axios from "axios";
import Swal from "sweetalert2";
import { AiOutlineDownload } from "react-icons/ai";
import { IoIosArrowDropup } from "react-icons/io";
import { IoIosArrowDropdown } from "react-icons/io";
import { debounce } from "lodash";

// import { GoDotFill } from "react-icons/go";
const Files = () => {
  const [files, setFiles] = useState([]);
  const [searchFileId, setSearchFileId] = useState("");
  const [searchFileName, setSearchFileName] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage] = useState(9);
  const urlParams = new URLSearchParams(window.location.search);
  const fileId = urlParams.get("fileId") || " ";

  const navigate = useNavigate("");
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  };
  const [expandedRows, setExpandedRows] = useState({});
  const getUsername = async (userId) => {
    try {
      const userDoc = await getDoc(doc(db, "userProfiles", userId));
      if (userDoc.exists()) {
        return userDoc.data().userName;
      } else {
        return "Unknown User";
      }
    } catch (error) {
      return "Unknown User";
    }
  };
  const fetchFiles = async () => {
    try {
      // if (fileId) {
      //   // Fetch a specific file if fileId is provided
      //   const fileDoc = await getDoc(doc(db, "filesManagement", fileId));
      //   if (fileDoc.exists()) {
      //     const fileData = fileDoc.data();
      //     const userName = await getUsername(fileData.userId);
      //     setFiles([{ id: fileId, ...fileData, userName }]);
      //   } else {
      //     console.error("File not found");
      //     setFiles([]);
      //   }
      // } else {
      const querySnapshot = await getDocs(collection(db, "filesManagement"));
      const filesArray = [];
      for (const doc of querySnapshot.docs) {
        const fileData = doc.data();
        const userName = await getUsername(fileData.userId);
        filesArray.push({ id: doc.id, ...fileData, userName });
      }
      filesArray.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

      setFiles(filesArray);
      // }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user") === process.env.REACT_APP_UIUID) {
      navigate("/");
    }

    fetchFiles();
    const unsubscribeFiles = onSnapshot(
      collection(db, "filesManagement"),
      async (snapshot) => {
        try {
          const filesArray = await Promise.all(
            snapshot.docs.map(async (doc) => {
              const fileData = doc.data();
              const userName = await getUsername(fileData.userId); // Fetch username
              return { id: doc.id, ...fileData, userName };
            })
          );
          filesArray.sort(
            (a, b) => new Date(b.startTime) - new Date(a.startTime)
          );
          setFiles(filesArray);
        } catch (error) {
          console.error("Error processing snapshot:", error);
        }
      },
      { includeMetadataChanges: true }
    );

    return () => {
      unsubscribeFiles();
    };
  }, [db, navigate]);
  const apiKey = process.env.REACT_APP_API_KEY1;
  const handleFileTypeSelection = (Type) => {
    const downloadUrl = `https://api.v2.badskip.com/skiptracing/download?fileId=${selectedFileId}&fileType=${Type}`;
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = `${selectedFileId}.csv`;
    a.click();

    setShowModal(false);
  };
  const toggleRow = (rowIndex) => {
    setExpandedRows((prev) => ({
      [rowIndex]: !prev[rowIndex],
    }));

  };
  const filteredFiles = files?.filter((file) => {
    if (fileId.trim()) {
      return file.id === fileId.trim();
    }
    const matchesFileId = searchFileId
      ? file.id?.toLowerCase().includes(searchFileId?.toLowerCase())
      : true;
    const matchesFileName = searchFileName
      ? file.fileName?.toLowerCase().includes(searchFileName?.toLowerCase())
      : true;
    const matchesStatus = statusFilter ? file.status == statusFilter : true;

    return matchesFileId && matchesFileName && matchesStatus;
  });

  // const currentRows = filteredFiles.slice(indexOfFirstRow, indexOfLastRow);

  const [showModal, setShowModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(0);

  const handleDownloadClick = (fileId, rowIndex) => {
    toggleRow(rowIndex);

    setSelectedFileId(fileId);
  };

  useEffect(() => {
    if (showModal) {
      const bootstrapLink = document.createElement("link");
      bootstrapLink.rel = "stylesheet";
      bootstrapLink.href =
        "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
      document.head.appendChild(bootstrapLink);

      return () => {
        document.head.removeChild(bootstrapLink);
      };
    }
  }, [showModal]);

  // const [isSending, setIsSending] = useState(false);
  // const [lastReceivedBatch, setLastReceivedBatch] = useState(null);

  useEffect(() => {
    const URL = "https://api.v2.badskip.com/";
    const socket = io(URL);

    socket.on("connect", () => {
      console.log("Connected to socket server");
    });

    const handleProgress = (data) => {
      if (data.fileId) {
        setFiles((prevTableData) =>
          prevTableData.map((file) =>
            file?.id === data.fileId
              ? { ...file, progress: data.progress }
              : file
          )
        );
      }
    };

    socket.on("progress", handleProgress);

    return () => {
      socket.off("progress", handleProgress);
      socket.disconnect();
    };
  }, []);

  // New function for refunding credits
  const handleRefundCredits = async (uid, fileId) => {
    try {
      const response = await axios.post(
        "https://api.v2.badskip.com/system/refundCredits",
        { uid: uid, fileId: fileId },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
        }
      );
      // console.log(apiKey);

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Credits Refunded",
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Refund Failed",
        text: "There was an issue processing the refund.",
      });
    }
  };

  return (
    <>
      <div className="mt-5">
        <Card extra="w-full pb-10 p-4 h-full">
          <header className="">
            <h5>Files</h5>
          </header>

          <div className="my-4 flex justify-around space-x-4">
            <input
              type="text"
              placeholder="Search by File Id"
              className="rounded border px-4 py-2"
              value={searchFileId}
              onChange={(e) => setSearchFileId(e.target.value)}
            />
            <input
              type="text"
              placeholder="Search by File Name"
              className="rounded border px-4 py-2"
              value={searchFileName}
              onChange={(e) => setSearchFileName(e.target.value)}
            />
            <select
              className="rounded border px-4 py-2"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="Completed">Completed</option>

              <option value="Checking">Checking</option>

              <option value="In Progress">In Progress</option>
              <option value="Processing">Processing</option>
              <option value="Optimizing">Optimizing</option>
              <option value="Refunded">Refunded</option>
              <option value="In Queue">In Queue</option>
              <option value="Cancelled">Cancelled</option>
              <option value="Uploading">Uploading</option>
              <option value="Received">Received</option>
            </select>
          </div>

          <div className="tableShowList mt-8 overflow-x-auto">
            <Table hover>
              <thead>
                <tr>
                  {/* <th>UUID</th> */}
                  <th>Name</th>
                  <th>Date</th>
                  <th>User ID</th>
                  <th>UserName</th>
                  <th>Status</th>

                  <th>Records</th>
                  <th>Refund</th>
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {filteredFiles?.map((row, rowIndex) => (
                  <>
                    <tr
                      key={rowIndex}
                      className={expandedRows[rowIndex] && "selectedTr"}
                    >
                      <td>
                        {row.status === "Completed" &&
                          (expandedRows[rowIndex] ? (
                            <IoIosArrowDropdown
                              onClick={() =>
                                handleDownloadClick(row.id, rowIndex)
                              }
                              className="dropicon"
                            />
                          ) : (
                            <IoIosArrowDropup
                              onClick={() =>
                                handleDownloadClick(row.id, rowIndex)
                              }
                              className="dropicon"
                            />
                          ))}

                        <p>{row.fileName}</p>
                      </td>
                      <td>
                        {row.startTime}
                        {/* row.startTime ? new Date(row.startTime).toLocaleString('en-US', options) : 'Loading ...' */}
                      </td>
                      <td>{row.userId}</td>

                      <td>{row.userName}</td>
                      <td>
                        {/* <div className={row.status=='In Progress' && row.progress ? 'tdprogress':null}> */}
                        <span className={`spanStatus ${row.status}`}>
                          {" "}
                          {row.status}
                        </span>

                        {row.status == "In Progress" && row.progress ? (
                          <div className="progress-container">
                            <span className="progress-text">
                              {row.progress}
                            </span>
                            <div className="progress-bar">
                              <div
                                className="progress-fill"
                                style={{
                                  width: `${(parseInt(row?.progress?.split("/")[0]) /
                                      parseInt(row?.progress?.split("/")[1])) *
                                    100
                                    }%`,
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                        {/* </div> */}
                      </td>

                      <td>{row.traces}</td>
                      <td className="px-8 py-4">
                        <button
                          onClick={() =>
                            handleRefundCredits(row.userId, row.id)
                          }
                        >
                          Refund
                        </button>
                      </td>
                      <td>
                        {/* <button onClick={() => toggleRow(rowIndex)} className="expandBtn">
                                                                {expandedRows[rowIndex] ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                                                            </button> */}
                        {row.status === "Completed" && (
                          <button
                            onClick={() =>
                              handleDownloadClick(row.id, rowIndex)
                            }
                            className="downloadBtn"
                          >
                            <AiOutlineDownload />
                          </button>
                        )}
                      </td>
                    </tr>
                    {expandedRows[rowIndex] && (
                      <>
                        {/* <tr>
                                                                <td colSpan="5">
                                                                    <div className="expanded-content">
                                                                        <strong>Additional Details:</strong>
                                                                        <p>Record ID: {row.id}</p>
                                                                        <p>Start Time: {new Date(row.startTime).toLocaleString()}</p>
                                                                    </div>
                                                                </td>
                                                            </tr> */}
                        <tr className="differ">
                          <td colSpan="2">File Name</td>
                          <td colSpan="5"></td>
                          <td colSpan="1">Actions</td>
                        </tr>
                        <tr className="rowdownload selectedTr">
                          <td colSpan="2">Cold Calling Optimized - Primary</td>
                          <td colSpan="5"></td>
                          <td colSpan="1">
                            <button
                              className="downloadBtn"
                              onClick={() =>
                                handleFileTypeSelection("primaryUrl")
                              }
                            >
                              <AiOutlineDownload />
                            </button>
                          </td>
                        </tr>
                        <tr className="rowdownload selectedTr">
                          <td colSpan="2">
                            Cold Calling Optimized - Secondary
                          </td>
                          <td colSpan="5"></td>
                          <td colSpan="1">
                            <button
                              className="downloadBtn"
                              onClick={() =>
                                handleFileTypeSelection("secondaryUrl")
                              }
                            >
                              <AiOutlineDownload />
                            </button>
                          </td>
                        </tr>
                        <tr className="rowdownload selectedTr">
                          <td colSpan="2">SMS Marketing Optimized</td>
                          <td colSpan="5"></td>
                          <td colSpan="1">
                            <button
                              className="downloadBtn"
                              onClick={() =>
                                handleFileTypeSelection("textingOptimizeUrl")
                              }
                            >
                              <AiOutlineDownload />
                            </button>
                          </td>
                        </tr>
                        {row.rawUrl ? (
                          <tr className="rowdownload selectedTr">
                            <td colSpan="2">Raw List Optimized</td>
                            <td colSpan="5"></td>
                            <td colSpan="1">
                              <button
                                className="downloadBtn"
                                onClick={() =>
                                  handleFileTypeSelection("rawUrl")
                                }
                              >
                                <AiOutlineDownload />
                              </button>
                            </td>
                          </tr>
                        ) : null}
                      </>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
          <br />
          {/* {renderPagination()} */}
        </Card>
        <>
          {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Select Download Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='d-flex justify-content-between flex-wrap'>
                <button className="buttonDownload m-2" onClick={() => handleFileTypeSelection('primaryUrl')}>
                  Cold Calling Optimized - Primary

                </button>
                <button className=" buttonDownload m-2" onClick={() => handleFileTypeSelection('secondaryUrl')}>
                  Cold Calling Optimized - Secondary

                </button>
                <button className=" buttonDownload m-2" onClick={() => handleFileTypeSelection('textingOptimizeUrl')}>
                  SMS Marketing Optimized
                </button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>

            </Modal.Footer>
          </Modal> */}
        </>
      </div>
    </>
  );
};

export default Files;
