import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  doc,
  setDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import { db } from "../../../firebase";
import Swal from "sweetalert2";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [passwordModal, setPasswordModal] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const [viewUser, setViewUser] = useState(null);
  const [toggleState, setToggleState] = useState({
    maintenance: false,
    pennyskips: false,
    titanskip: false,
    manual: false,
    tracerfy: false,
  });
  const [credits, setCredits] = useState(0);
  const [titanCredits, setTitanCredits] = useState(0);
  const [pennyCredits, setPennyCredits] = useState(0);
  const [tracerfyCredits, setTracerfyCredits] = useState(0);

  const [userCredits, setUserCredits] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newCredits, setNewCredits] = useState(0);
  const navigate = useNavigate("");
  const urlParams = new URLSearchParams(window.location.search);
  const apiKey = process.env.REACT_APP_API_KEY1;
  const userId = urlParams.get("uid") || " ";

  const fetchUserProfiles = async () => {
    try {
      const snapshot = await getDocs(collection(db, "userProfiles"));
      const usersArray = [];
      let totalUserCredits = 0;

      snapshot.forEach((doc) => {
        const userData = { id: doc.id, ...doc.data() };
        usersArray.push(userData);

        if (
          [
            process.env.REACT_APP_UIUID,
            process.env.REACT_APP_UIUID2,
            process.env.REACT_APP_UIUID3,
            process.env.REACT_APP_UIUID4,
          ].includes(userData.id)
        ) {
          totalUserCredits += userData.credits || 0;
        }
      });

      setUsers(usersArray);
      setUserCredits(totalUserCredits);
    } catch (error) {
      console.error("Error fetching user profiles: ", error);
    }
  };

  useEffect(() => {
    const userId = localStorage.getItem("user");

    if (userId !== process.env.REACT_APP_UIUID) {
      navigate("/");
      return;
    }

    fetchUserProfiles();

    const docRef = doc(db, "dashBoard", "system");
    const unsubscribeToggles = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setToggleState(doc.data());
      }
    });

    const docRef2 = doc(db, "dashBoard", "systemCredits");
    const unsubscribeToggles2 = onSnapshot(docRef2, (doc) => {
      if (doc.exists()) {
        setCredits(doc.data().credits);
        setPennyCredits(doc.data().pennyCredits);
        setTitanCredits(doc.data().titanCredits);
        setTracerfyCredits(doc.data().tracerfy);
      }
    });

    return () => {
      unsubscribeToggles();
      unsubscribeToggles2();
    };
  }, [db, navigate]);
  const handleToggle = async (category) => {
    const newState = !toggleState[category];
    const newToggleState = { ...toggleState, [category]: newState };

    setToggleState(newToggleState);

    const changedToggle = { [category]: newState };

    try {
      const response = await fetch(
        "https://api.v2.badskip.com/system/updateStatus",
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "x-api-key": apiKey },
          body: JSON.stringify(changedToggle),
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  // const filteredUsers = users.filter((user) => {
  //   return (
  //     (user.email && user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //     (user.companyEmail && user.companyEmail.toLowerCase().includes(searchQuery.toLowerCase()))
  //   );
  // });
  const filteredUsers = users.filter((user) => {
    if (userId.trim()) {
      return user.id === userId.trim();
    }
    return (
      (user.email &&
        user.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (user.companyEmail &&
        user.companyEmail.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  const handleEdit = (user) => {
    setSelectedUser(user);
    setNewCredits(user.credits);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  const handleSave = async () => {
    if (selectedUser) {
      try {
        const response = await fetch(
          "https://api.v2.badskip.com/system/addCredits",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apiKey,
            },
            body: JSON.stringify({ amount: newCredits, uid: selectedUser.id }),
          }
        );

        if (response.ok) {
          handleClose();
          fetchUserProfiles();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  // const handleSave = async () => {
  //   if (selectedUser) {
  //     const userRef = doc(db, "userProfiles", selectedUser.id);
  //     const currentCredits = selectedUser.credits || 0;

  //     const updatedCredits = currentCredits + newCredits;

  //     await setDoc(userRef, { credits: updatedCredits }, { merge: true });

  //     const systemCreditsRef = doc(db, "dashBoard", "systemCredits");
  //     const systemCreditsSnapshot = await getDoc(systemCreditsRef);

  //     if (systemCreditsSnapshot.exists()) {
  //       const systemCreditsData = systemCreditsSnapshot.data();

  //       const currentSystemCredits = systemCreditsData.credits || 0;

  //       const updatedSystemCredits = currentSystemCredits + newCredits;

  //       await setDoc(systemCreditsRef, { credits: updatedSystemCredits }, { merge: true });
  //     }

  //     handleClose();
  //     // window.location.reload()
  //   }
  // };

  useEffect(() => {
    if (showModal) {
      const bootstrapLink = document.createElement("link");
      bootstrapLink.rel = "stylesheet";
      bootstrapLink.href =
        "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
      document.head.appendChild(bootstrapLink);

      return () => {
        document.head.removeChild(bootstrapLink);
      };
    }
  }, [showModal]);
  const remainingCredits = credits ? credits - userCredits : 0;
  // const handleView = async (uid,user) => {
  //   const url = "https://api.v2.badskip.com/user/admin-check";
  // const password = passKey
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         'x-api-key': apiKey

  //       },
  //       body: JSON.stringify({ uid, password }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       if(data.admin == false){
  //         localStorage.setItem('uid', uid)
  //         localStorage.setItem('credits', user.credits)
  //         localStorage.setItem('type', user.type)
  //         localStorage.setItem('stripeCustomerId', user.stripeCustomerId)
  //          navigate("/Home")
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user details: ", error);
  //     Swal.fire("Error", "An unexpected error occurred", "error");
  //   }
  // };

  const handleView = (uid, user) => {
    setViewUser({ uid, user });
    setPasswordModal(true);
  };

  const handlePasswordSubmit = async () => {
    const { uid, user } = viewUser;
    const url = "https://api.v2.badskip.com/user/admin-check";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
        body: JSON.stringify({ uid, password: enteredPassword }),
      });

      if (response.ok) {
        const data = await response.json();
        if (!data.admin) {
          localStorage.setItem("uid", uid);
          localStorage.setItem("credits", user.credits);
          localStorage.setItem("type", user.type);
          localStorage.setItem("stripeCustomerId", user.stripeCustomerId);
          navigate("/Home");
        } else {
          Swal.fire("Error", "Unauthorized Access", "error");
        }
      }
    } catch (error) {
      console.error("Error fetching user details: ", error);
      Swal.fire("Error", "An unexpected error occurred", "error");
    } finally {
      setPasswordModal(false);
      setEnteredPassword("");
    }
  };
  return (
    <>
      <div className="mt-5">
        <Card extra="w-full pb-10 p-4 h-full">
          <header className="">
            <div className="flexToggle flex">
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Maintenance</span>
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={toggleState.maintenance}
                    onChange={() => handleToggle("maintenance")}
                  />
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Penny Skips</span>
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={toggleState.pennyskips}
                    onChange={() => handleToggle("pennyskips")}
                  />
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Titan Skips</span>
                  <input
                    className="toggle"
                    type="checkbox"
                    checked={toggleState.titanskip}
                    onChange={() => handleToggle("titanskip")}
                  />
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Manual</span>
                  <input
                    className="toggle"
                    type="checkbox"
                    checked={toggleState.manual}
                    onChange={() => handleToggle("manual")}
                  />
                </label>
              </div>
              <div>
                <label className="flex items-center">
                  <span className="mr-2">Tracerfy</span>
                  <input
                    className="toggle"
                    type="checkbox"
                    checked={toggleState.tracerfy}
                    onChange={() => handleToggle("tracerfy")}
                  />
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <h5 className="my-6">
                {" "}
                Total Credits : ({remainingCredits ? remainingCredits : 0}){" "}
              </h5>
              <h5 className="my-6">
                {" "}
                Titan Credits : ({titanCredits ? titanCredits : 0}){" "}
              </h5>

              <h5 className="my-6">
                {" "}
                Penny Credits : ({pennyCredits ? pennyCredits : 0}){" "}
              </h5>
              <h5 className="my-6">
                {" "}
                Tracerfy Credits : ({tracerfyCredits
                  ? tracerfyCredits
                  : 0}){" "}
              </h5>
            </div>
            <input
              type="text"
              placeholder="Search by email"
              className="w-full border p-2"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </header>

          <div className="mt-8 overflow-x-auto">
            <table className="w-full whitespace-nowrap">
              <thead>
                <tr className="bg-gray-100 dark:bg-gray-800">
                  <th className="px-8 py-4 ">User Id</th>

                  <th className="px-8 py-4 ">Name</th>
                  <th className="px-8 py-4 ">Email</th>
                  <th className="px-8 py-4 ">Phone</th>
                  <th className="px-8 py-4 ">View</th>

                  <th className="px-8 py-4 ">Credits</th>
                  <th className="px-8 py-4 ">Add Credits</th>
                  <th className="px-8 py-4 ">Optimizer</th>

                  <th className="px-8 py-4 ">Company Name</th>
                  <th className="px-8 py-4 ">Website</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id} className="border-b dark:border-gray-700">
                    {user.type === "callcenter" ? (
                      <>
                        <td className="px-8 py-4">{user.id}</td>

                        <td className="px-8 py-4">
                          {user.principalFirstName} {user.principalLastName}
                        </td>
                        <td className="px-8 py-4">{user.companyEmail}</td>
                        <td className="px-8 py-4">
                          {user.principalPhoneNumber}
                        </td>
                        <td className="px-8 py-4">
                          <button
                            variant="primary"
                            onClick={() => handleView(user.id, user)}
                          >
                            <FaEye />
                          </button>
                        </td>

                        <td className="px-8 py-4">{user.credits}</td>
                        <td className="px-8 py-4">
                          <button
                            variant="primary"
                            onClick={() => handleEdit(user)}
                          >
                            Add
                          </button>
                        </td>
                        <td className="px-8 py-4">
                        <Link to={`/admin/Optimizer?uid=${user.id}`}>
                          <button
                            variant="primary"
                            
                          >
                            Optimize
                          </button>
                          </Link>
                        </td>
                        <td className="px-8 py-4">{user.companyName}</td>
                        <td className="px-8 py-4">
                          {user.companyWebsiteOrSocialMedia}
                        </td>
                        
                      </>
                    ) : (
                      <>
                        <td className="px-8 py-4">{user.id}</td>

                        <td className="px-8 py-4">
                          {user.firstName} {user.lastName}
                        </td>
                        <td className="px-8 py-4">{user.email}</td>
                        <td className="px-8 py-4">{user.phoneNumber}</td>
                        <td className="px-8 py-4">
                          <button
                            variant="primary"
                            onClick={() => handleView(user.id, user)}
                          >
                            <FaEye />
                          </button>
                        </td>
                        <td className="px-8 py-4">{user.credits}</td>
                        <td className="px-8 py-4">
                          <button
                            variant="primary"
                            onClick={() => handleEdit(user)}
                          >
                            Add
                          </button>
                        </td>
                        <td className="px-8 py-4">
                        <Link to={`/admin/Optimizer?uid=${user.id}`}>
                          <button
                            variant="primary"
                            
                          >
                            Optimize
                          </button>
                          </Link>
                        </td>
                      </>
                    )}
                  </tr>
                 
                ))}
              </tbody>
            </table>
          </div>
          <br />

          {/* {renderPagination()} */}
          <Modal show={passwordModal} onHide={() => setPasswordModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Enter Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="passwordInput">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    value={enteredPassword}
                    onChange={(e) => setEnteredPassword(e.target.value)}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setPasswordModal(false)}
              >
                Close
              </Button>
              <button variant="primary" onClick={handlePasswordSubmit}>
                Submit
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Credits for {selectedUser?.email}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="formNewCredits">
                  <Form.Label>Credits</Form.Label>
                  <Form.Control
                    type="number"
                    // value={newCredits}
                    onChange={(e) => setNewCredits(Number(e.target.value))}
                    placeholder="Enter new credits"
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <button onClick={handleSave}>Save</button>
            </Modal.Footer>
          </Modal>
        </Card>
      </div>
    </>
  );
};

export default Users;
