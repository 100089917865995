import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Footer from "./Footer";
import {
  getAuth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { Helmet } from "react-helmet";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { GoDotFill } from 'react-icons/go';
import { updateDoc } from "firebase/firestore";

import axios from "axios";
import NavBar from "./Navbarr";
function Account() {
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();
  const apiKey = process.env.REACT_APP_API_KEY1;

  const [user, setUser] = useState({
    full_name: "",
    email: "",
    phone: "",
    userName: "",
  });
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [referredUsers, setReferredUsers] = useState([]);

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (!uid) {
      navigate("/");
      return;
    }

    const fetchUserData = async () => {
      const docRef = doc(db, "userProfiles", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        setUser(userData);
        fetchReferredUsers(userData.userName);
      } else {
        console.error("No user data found!");
      }
    };

    const fetchReferredUsers = async (username) => {
      if (!username) return;

      const q = query(
        collection(db, "userProfiles"),
        where("referrer", "==", username)
      );
      const querySnapshot = await getDocs(q);

      const users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data().userName);
      });

      setReferredUsers(users);
    };

    fetchUserData();
  }, [db, navigate]);

  const onPasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleProfileUpdate = async (event) => {
    event.preventDefault();

    const uid = localStorage.getItem("uid");

    if (!uid) {
      return;
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US");
    const dateWithoutAmPm = formattedDate.slice(0, -3);
    const amPm = formattedDate.slice(-3);
    const milliseconds = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    const formattedDateWithMilliseconds = `${dateWithoutAmPm}.${milliseconds}${amPm}`;

    try {
      let photoUrl = "";

      if (selectedFile) {
        const storageRef = ref(
          storage,
          `/files/${
            formattedDateWithMilliseconds.replaceAll("/", "_") +
            selectedFile.name
          }`
        );

        await uploadBytes(storageRef, selectedFile);
        photoUrl = await getDownloadURL(storageRef);
      }

      const userDocRef = doc(db, "userProfiles", uid);
      await updateDoc(userDocRef, {
        photoUrl: photoUrl,
      });
      Swal.fire({
        icon: "success",
        title: `Updated Succesfully`,
        showConfirmButton: true,
        timer: 1500,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    } catch (error) {
      console.error("Error uploading file and updating document:", error);
      Swal.fire({
        icon: "error",
        title: `Something Went Wrong`,
        showConfirmButton: true,
        timer: 1500,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();

    if (passwords.newPassword !== passwords.confirmNewPassword) {
      Swal.fire({
        icon: "error",
        title: `Passwords do not match!`,
        showConfirmButton: true,
        timer: 1500,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
      return;
    }

    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        passwords.currentPassword
      );

      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, passwords.newPassword);
      Swal.fire({
        icon: "success",
        title: `Password updated successfully!`,
        showConfirmButton: true,
        timer: 1500,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      }).then(
        setPasswords({
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        })
      );
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        Swal.fire({
          icon: "error",
          title: `Incorrect current password!`,
          showConfirmButton: true,
          timer: 1500,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      } else if (error.code === "auth/requires-recent-login") {
        Swal.fire({
          icon: "error",
          title: `Please log in again to update your password.`,
          showConfirmButton: true,
          timer: 1500,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: error.message,
          showConfirmButton: true,
          timer: 1500,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      }
    }
  };

  const handleCopyInvitationLink = () => {
    const invitationLink = `${window.location.origin}/Register?username=${user.userName}`;
    navigator.clipboard
      .writeText(invitationLink)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Invitation link copied to clipboard.",
          showConfirmButton: true,
          timer: 1500,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Failed to copy the link.",
          showConfirmButton: true,
          timer: 1500,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      });
  };
  const manageSubscribe = async () => {
    const uid = localStorage.getItem("uid");

    try {
      const payload = {
        uid: uid,
      };
      const response = await axios.post(
        "https://api.v2.badskip.com/payments/billing-portal",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
          },
        }
      );

      window.location.href = response.data.url;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: " failed",
        text: error.response.data.message,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>BadSkip | Account </title>
      </Helmet>
      <NavBar />

      <section className="homeFourth formSection accountEdit inquiry">
        <h2>Account Settings</h2>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div className="formDiv">
                <form onSubmit={handleProfileUpdate}>
                  <div className="row justify-content-center px-5">
                    <div className="col-md-3">
                      <div>
                        <input
                          type="file"
                          id="fileInput"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="fileInput">
                          {selectedFile ? (
                            <div className="imageFile upload">
                              <img
                                src={URL.createObjectURL(selectedFile)}
                                alt="upload-profile"
                              />
                            </div>
                          ) : (
                            <div className="imageFile imggg">
                              <img
                                src={
                                  user.photoURL ||
                                  user.photoUrl ||
                                  "images/Group.svg"
                                }
                                alt="upload-profile"
                              />
                            </div>
                          )}
                        </label>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <button type="submit">Upload Photo</button>
                    </div>
                  </div>
                </form>

                <div className="row">
                  <div className="col-md-6">
                    <label>User Name</label>
                    <p>{user.userName}</p>
                  </div>

                  <div className="col-md-6">
                    <label>Email</label>
                    <p>
                      {user.type === "callcenter"
                        ? user.principalEmail
                        : user.email}
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label>Invitation Link</label>
                    <div className="row">
                      <div className="col-md-6">
                        <p>{`${window.location.origin}/Register?username=${user.userName}`}</p>
                      </div>
                      <div className="col-md-6">
                        <button
                          style={{ marginLeft: "10px" }}
                          type="button"
                          onClick={handleCopyInvitationLink}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt-3">
                    <label>Manage Subscribtion</label>
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          style={{ marginLeft: "10px" }}
                          type="button"
                          onClick={manageSubscribe}
                        >
                          Click Here
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {referredUsers.length > 0 ? (
                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label>Referred Users</label>
                      <ul>
                        {referredUsers.map((referredUser, index) => (
                          <li key={index}>
                            {/* <GoDotFill />  */}
                            {referredUser}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ) : null}
                <form onSubmit={handlePasswordChange}>
                  <div className="row justify-content-between mt-5">
                    <div className="col-md-12">
                      <label>Current Password</label>
                      <input
                        className="form-control"
                        name="currentPassword"
                        value={passwords.currentPassword}
                        onChange={onPasswordChange}
                        type="password"
                        placeholder="Current Password"
                      />
                    </div>
                    <div className="col-md-5">
                      <label>New Password</label>
                      <input
                        className="form-control"
                        name="newPassword"
                        value={passwords.newPassword}
                        onChange={onPasswordChange}
                        type="password"
                        placeholder="New Password"
                      />
                    </div>
                    <div className="col-md-5">
                      <label>Confirm New Password</label>
                      <input
                        className="form-control"
                        name="confirmNewPassword"
                        value={passwords.confirmNewPassword}
                        onChange={onPasswordChange}
                        type="password"
                        placeholder="Confirm New Password"
                      />
                    </div>
                  </div>
                  <button type="submit">Change Password</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Account;
