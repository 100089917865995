import React from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col
            lg="3"
            md="3"
            sm="12"
            className="wow fadeInUp span12 offcanlink"
            data-wow-duration="0.5s"
          >
            <img src="images/Layer 16 (1).svg" alt="logo" />
          </Col>
          <Col
            lg="5"
            md="5"
            sm="12"
            className="wow fadeInUp servicesFooter"
            data-wow-duration="0.5s"
          >
            <h5>Services</h5>
            <div>
              <Link to="/skiptracing">Skiptracing </Link>
              {/* <Link to="/">Scrubbing</Link> */}
              <Link to="/ListUpload">Build Your VA Team </Link>
            </div>
          </Col>
          <Col
            lg="4"
            md="4"
            sm="12"
            className="wow fadeInUp span12 emailFooter"
            data-wow-duration="0.5s"
          >
            <h5>EMAIL US</h5>
            <a href="mailto:Support@badskip.com">
              <p>Support@badskip.com </p>
            </a>
          </Col>
          {/* <Col
            lg="2"
            md="2"
            sm="12"
            className="wow fadeInUp span12 callFooter"
            data-wow-duration="0.5s"
          >
            <h5>CALL US</h5>
            <div>
              <a href="tel:+1 (786) 485-0848  ">
                Sales Team:
                <br />
                <span>+1 (786) 485-0848 </span>
              </a>
              <br />

              <a href="tel:+1 (310) 601-8347 ">
                Support Team:
                <br />
                <span>+1 (310) 601-8347</span>
              </a>
            </div>
          </Col> */}
        </Row>
        <div className="iconsfooter wow fadeInUp ">
          <a href="https://www.facebook.com/profile.php?id=61565232362002">
            <img src="images/Layer 12.svg" alt="fb" />
          </a>
          <a href="https://www.linkedin.com/company/badskip/">
            <img src="images/Layer 13.svg" alt="linkedin" />
          </a>

          <a href="https://www.instagram.com/badskip?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
            <img src="images/Group 5.svg" alt="insta" />
          </a>

          {/* <a href="">
            <img src="images/socialicon.svg" alt="twitter" />
          </a> */}
        </div>
        {/* <div className="footerBorder">
          <div className="copyRight wow fadeInUp ">
            <p>Copyright © 2024 Extronnect LLC. All Rights Reserved.</p>
            <div>
              <Link to="/terms-and-conditions" >
                Terms and Conditions
              </Link>
              <Link to="/policy">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div> */}
      </Container>
    </footer>
  );
}

export default Footer;
