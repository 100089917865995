import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Col, Container, NavbarBrand, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Swal from "sweetalert2";
import { db } from "./firebase";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { Helmet } from "react-helmet";
import NavBar from "./Navbarr";
const Preloader = () => <div class="loader"></div>;
const Home = () => {
  const [formData, setFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const navigate = useNavigate("");
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US");
    const year = new Date(formattedDate).getFullYear();
    const month = (new Date(formattedDate).getMonth() + 1)
      .toString()
      .padStart(2, "0");
    const day = new Date(formattedDate).getDate().toString().padStart(2, "0");

    const dateWithoutAmPm = formattedDate.slice(0, -3);
    const amPm = formattedDate.slice(-3);
    const milliseconds = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    const formattedDateWithMilliseconds = `${dateWithoutAmPm}.${milliseconds}${amPm}`;

    const uid = formattedDateWithMilliseconds
      .replaceAll("/", "_")
      .replaceAll(":", "-")
      .replace(", ", "@")
      .replace(" ", "#");

    const docName = `${day}_${month}_${year}`;

    const submitData = {
      [uid]: formData,
    };

    await setDoc(doc(db, "messages", docName), submitData, {
      merge: true,
    });
    const emailHtml = `Hey ${formData.name},<br/>We have received your Message submitted via our website. Our team is on it and will get back to you as soon as possible. If you have any questions in the meantime, feel free to reach out by replying to this email.<br/><br/>Thank you,<br/><br/>`;
    const signatureHtml = `  <font color="#888888">
            <span class="gmail_signature_prefix">-- </span><br>
            <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
                <div dir="ltr">
                    <table class="signature-table" width="414" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                            <tr>
                                <td valign="top" class="signature-cell">
                                    <table border="0" cellpadding="0" cellspacing="0" width="414" class="inner-table">
                                        <tbody>
                                            <tr>
                                            <!--
                                                <td class="no-padding">
                                                    <span class="name-title"><font face="arial black, sans-serif">Samir H</font></span>
                                                </td>
                                                -->
                                                <td class="no-padding"></td>
                                            </tr>
                                            <tr>
                                                <td class="no-padding">
                                                    <span class="position-title"><font face="arial black, sans-serif">Client Success Manager<br><br></font></span>
                                                </td>
                                                <td align="right" width="170" class="no-padding">
                                                    <p dir="ltr" class="social-links">
                                                        <span>
                                                            <a href="http://badskip.com/" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/ERQye9fHlkGU5k3qeAOaIPlXt6WEXXaU3Qk6sX-RPc989aGEznY7hiAxlQrZZy4jbAFXGrMQpBM7XooSYiCJQoTXjmwx2feUNpI2IMZAFp4rcsQCUmyJTDeeYB7wXVKx2ajwEOROluc4SDSAovfxy44" width="23" height="23" alt="Website" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://www.instagram.com/badskip/" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/RBHXzA6EarsjkC28LnCKIOX46gSouRWQvToQulLKq_T8amnmvJz3GO1Pd5GML1bps41dLRSlJe8y3UR6olRNRWBBzflLsAPtb2KI9n6PynABhc6WXrH3CrSimE6b-7cn4ntkAr6EnTkr2i0Qw9QQr0w" width="23" height="23" alt="Instagram" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                       <!--

                                                        <span>
                                                            <a href="https://wa.me/15619225376" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/Rgf8cTDfganYaSmVeRG8an1XXfBmeubjqi1eCPjKsprqPPgN6A-isjGF-TvzNNacha4ut_sMzFYXo3DbOEMb9SIcp4vA1kKnvykcTww8iV_wh2L6GsyVb9aTQiZO5e7m-2hESYGqdtbnOFF8WJBWuUg" width="23" height="23" alt="WhatsApp" class="icon">
                                                            </a>&nbsp;
                                                        </span>
                                                        <span>
                                                            <a href="https://www.linkedin.com/company/100955777" target="_blank">
                                                                <img src="https://lh7-us.googleusercontent.com/0554XXBDxwmR3G1JA7ijlqWufEnXfK_l1LUpuqg-eydQVjai3t0GXbVp1xrqfvKH1g1thL1EzqjUpMqvwITbTb7ndmCv1125NqDuBN45wsCQkCB17bxXIpAEfbCMv_G2WQwpAqFp6vIBmNo3uqgzKMI" width="23" height="23" alt="LinkedIn" class="icon">
                                                            </a>
                                                        </span>
                                                        -->
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                            
                                                <td class="no-padding">
                                                    <span class="contact-info">
                                                       <!--

                                                        <span class="icon-cell">
                                                            <img src="https://lh7-us.googleusercontent.com/qv5uavYFMPAfRw6bWUk8GYOV-ngLGdu4ndYfyql8dq8Cogw2Jfgm4IZhbUIyj50D0kNLCA-J8mF_YCkw5RrKm-6p3cQog0MTiVkR6WE_EK5fcMgLP9ufvtzS_N87-tNRUJuucuG9sNvShAplf9oDnW4" width="19" height="19" class="contact-icon">
                                                        </span>
                                                        <font face="arial black, sans-serif">(310) 601-8347</font>
                                                        <br>
                                                        <span class="icon-cell">
                                                            <img src="https://lh7-us.googleusercontent.com/d6sEan4jectEq-6hIecRokXTA4nHSmFDVk0v71K5EXwxoepvfnMyxoNfUhrrTlVOLbssr81WHwRaBO-IhvFHOrD2vkl0gsB7nmxPznFc_YGxFTRiwwvhQMgxlr4i3mPJ2gz9SSNdsbZFLQMRs0JZzQo" width="19" height="19" class="contact-icon">
                                                        </span>&nbsp;
                                                        -->
                                                        <a href="mailto:support@badskip.com" class="email-link">support@badskip.com</a>&nbsp; &nbsp; &nbsp;
                                                    </span>
                                                </td>
                                                <td valign="bottom" align="right" class="no-padding"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                                                                                   <!--

                            <tr>
                                <td class="banner-cell">

                                    <a href="https://badskip.com/" target="_blank">
                                        <img border="0" alt="Banner" width="414" src="https://ci3.googleusercontent.com/meips/ADKq_Nao5PJGW-cu5EO2By_8Ke9Dcl1rL90Pfc7dZ7nzBIH9qx9rk_HYSLsB2oqY6rLLWq_UIJ-n=s0-d-e1-ft#https://i.imgur.com/u495xLU.png" class="banner-image">
                                    </a>
                                </td>
                            </tr>
                            -->
                            <tr>
                                <td class="disclaimer-cell">
                                    The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </font>`;
    const fullHtml = emailHtml + signatureHtml;

    addDoc(collection(db, "mail"), {
      to: formData.email,
      message: {
        subject: "badskip | We Have Received Your Message!",
        html: fullHtml,
      },
    });
    await addDoc(collection(db, "mail"), {
      to: "support@badskip.com",
      message: {
        subject: "New Message Submitted",
        html: `<code><h1>New Message</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Phone: ${formData.phone}</h2><h2>Message: ${formData.message}</h2></code>`,
      },
    });
    event.target.reset();
    Swal.fire({
      icon: "success",
      title: "Sent successfully",
      text: "Message Sent successfully ",
      timer: 1000,
      customClass: {
        confirmButton: "my-confirm-button",
      },
    }).then(handleNewInquiry);
  };

  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (!uid) {
      navigate("/admin/Users");
      return;
    }
  }, [navigate]);

  const handleNewInquiry = () => {
    setFormData({});
    setIsSubmitting(false);
  };
  return (
    <>
      <NavBar />

      <Helmet>
        <title>BadSkip | Locate, Scrub & Connect</title>
      </Helmet>
      <NavBar />
      <section className="homeFirst">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="firstDiv">
                <h1>Unmask the Hidden</h1>
                <p>
                  Because Finding People
                  <br />
                  Shouldn't Be Rocket Science.
                </p>
                <a
                  href="https://calendar.app.google/11BuZVe3vWa3HhN99"
                  target="_blank"
                >
                  <button> Book a Demo</button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homeSecond">
        <p>
          Welcome to <span>BadSkip</span>, where we turn the impossible into the
          inevitable.
          <br />
          We specialize in locating the unlocatable, giving you the power to
          connect
          <br />
          with the people who matter most to your business. Dive in and discover
          <br />
          how we transform elusive leads into valuable connections.
        </p>
      </section>
      <section className="homeThird">
        <h2>Our Services:</h2>
        <Container>
          <Row className="justify-content-around">
            <Col lg={4} md={4} sm={12}>
              <div>
                <h3>Skiptracing</h3>
                <img src="images/Group (2).svg" alt="skiptracing" />
                <p>
                  Identify and locate potential
                  <br />
                  leads with precision.
                </p>
                <Link to="/skiptracing">
                  <button>Read More</button>
                </Link>
              </div>
            </Col>
            {/* <Col lg={4} md={4} sm={12}>
                            <div>
                                <h3>Scrubbing</h3>
                                <img src="images/Layer 5 (2).svg" alt="" />
                                <p>
                                    Cleanse your contact lists
                                    <br />
                                    for maximum efficiency.
                                </p>
                                <button>Read More</button>
                            </div>
                        </Col> */}
            <Col lg={4} md={4} sm={12}>
              <div>
                <h3>Build Your VA Team</h3>
                <img src="images/Group 3.svg" alt="build va team" />
                <p>
                  Build and manage your
                  <br />
                  high-performance calling team
                </p>
                <Link to="/build-va-team">
                  <button>Read More</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homeFourth">
        <h2>About Us:</h2>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="rightfourth">
                <h3>Who We Are</h3>
                <p>
                  Our mission is straightforward: to transform the frustration
                  of dead-end leads into the satisfaction of reliable
                  connections. Whether you're a real estate professional, a call
                  center manager, or anyone needing pinpoint accurate data,{" "}
                  <span>BadSkip</span> is your trusted partner in revealing the
                  hidden.
                </p>
                <p>
                  We’re not just another skip tracing company; we are a
                  dedicated team passionate about bringing{" "}
                  <span>efficiency, accuracy, and reliability</span> to your
                  lead generation efforts. ensuring that you get the best
                  results, every time.
                </p>
                <Link to="/about-us">
                  <button>More Info</button>
                </Link>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="imgmob">
              <img
                src="images/background-concept-with-business-people-silhouette-work 01.png"
                alt="about"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="homeFourth formSection" id="Contact">
        <h2>Contact Us:</h2>
        <Container fluid>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <img
                src="images/handsome-young-man-working-with-laptop-mobile-phone-office.png"
                alt="contact"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="formDiv">
                <form onSubmit={handleSubmit}>
                  <h3>Get in Touch</h3>
                  <div>
                    <input
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <input
                      className="form-control"
                      name="email"
                      placeholder="Email Address"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <PhoneInput
                      country={"us"}
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      inputClass="form-control"
                      onChange={(value) =>
                        handleChange({ target: { name: "phone", value } })
                      }
                      placeholder="Phone Number"
                    />
                  </div>
                  <div>
                    <textarea
                      name="message"
                      className="form-control"
                      placeholder="Message"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div>
                    <button type="submit" disabled={isSubmitting}>
                      {" "}
                      {isSubmitting ? <Preloader /> : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Home;
