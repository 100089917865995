import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import axios from "axios";
import Swal from "sweetalert2";
import { HashLink } from "react-router-hash-link";
import NavBar from "./Navbarr";

function Pricing() {
  const navigate = useNavigate();
  const storedUserType = localStorage.getItem("type");
  const [credits, setCredits] = useState(10000);
  const [couponCode, setCouponCode] = useState(" ");
  const [pricePerCredit, setPricePerCredit] = useState(
    storedUserType === "callcenter" ? 0.025 : 0.08
  );
  const [totalPrice, setTotalPrice] = useState(
    storedUserType === "callcenter"
      ? (10000 * 0.025).toFixed(2)
      : (10000 * 0.08).toFixed(2)
  );
  const [discount, setDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(totalPrice);
  const apiKey = process.env.REACT_APP_API_KEY;
  useEffect(() => {
    const uid = localStorage.getItem("uid");
    if (!uid) {
      navigate("/");
      return;
    }
  }, [navigate]);
  const calculatePrice = (credits) => {
    let price = 0;
    if (storedUserType === "callcenter") {
      if (credits <= 9999) price = 0.03;
      else if (credits <= 19999) price = 0.025;
      else price = 0.025;
    } else {
      if (credits <= 9999) price = 0.085;
      else if (credits <= 19999) price = 0.08;
      else price = 0.07;
    }
    const total = (credits * price).toFixed(2);
    setPricePerCredit(price);
    setTotalPrice(total);
    setDiscountedPrice(0);
    setDiscount(0);

    // if (discount > 0) {
    //   setDiscountedPrice((total * (1 - discount / 100)).toFixed(2));
    // }
  };

  const handleCreditsChange = (e) => {
    const numericValue = parseInt(e.target.value, 10) || 0;
    setCredits(numericValue);
    calculatePrice(numericValue);
  };

  const handleApplyCoupon = async () => {
    if (couponCode.length > 1) {
      try {
        const response = await axios.post(
          "https://api.v2.badskip.com/payments/coupon",
          {
            couponCode: couponCode,
            userType: storedUserType,
            amount: credits,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apiKey,
            },
          }
        );

        if (response.data.valid) {
          const discountValue = response.data.discount.includes("%")
            ? parseFloat(response.data.discount.replace("%", "")) / 100
            : parseFloat(response.data.discount.replace("$", ""));

          const newPrice = response.data.discount.includes("%")
            ? totalPrice * (1 - discountValue)
            : totalPrice - discountValue;

          setDiscount(discountValue * 100);
          setDiscountedPrice(newPrice.toFixed(2));

          Swal.fire({
            icon: "success",
            title: "Coupon applied",
            text: `You received a ${response.data.discount} discount!`,
            customClass: {
              confirmButton: "my-confirm-button",
            },
          });
        } else {
          setDiscount(0);
          setDiscountedPrice(totalPrice);
          Swal.fire({
            icon: "error",
            title: "Invalid Coupon",
            text: response.data.message,
            customClass: {
              confirmButton: "my-confirm-button",
            },
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Enter Valid Coupon",
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    }
  };

  const handleCompletePurchase = async () => {
    const uid = localStorage.getItem("uid");
    //  if (credits && uid) {
    //     try {

    //       const data = {
    //         price: discount > 0? discountedPrice : totalPrice,
    //         credits: credits,
    //         uid: uid,
    //         ...(discount > 0 && { couponCode })
    //       };
    //       console.log(data);

    //       navigate('/ContactPricing', { state: data });

    //     } catch (error) {
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Purchase failed',
    //         text: error.response?.data?.message || 'An error occurred while processing your purchase.',
    //         customClass: {
    //           confirmButton: 'my-confirm-button',
    //         }
    //       });
    //     }
    //   } else {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Invalid Amount',
    //       text: 'Please enter a valid amount of credits.',
    //       customClass: {
    //         confirmButton: 'my-confirm-button',
    //       }
    //     });
    //   }

    if (credits && uid) {
      try {
        const payload = {
          amount: credits,
          uid: uid,
        };
        if (discount > 0) payload.couponCode = couponCode;

        const response = await axios.post(
          "https://api.v2.badskip.com/payments/checkout",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apiKey,
            },
          }
        );
        window.location.href = response.data.url;
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Purchase failed",
          text:
            error.response?.data?.message ||
            "An error occurred while processing your purchase.",
          customClass: {
            confirmButton: "my-confirm-button",
          },
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Invalid Amount",
        text: "Please enter a valid amount of credits.",
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    }
  };
  const CompletePurchase = async (value) => {
    const uid = localStorage.getItem("uid");

    // try {
    //   const data = {
    //     subscriptionType: value,
    //     uid: uid,
    //   };
    //   console.log(data);

    //   navigate('/ContactPricing', { state: data });

    // } catch (error) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Subscription Failed',
    //     text: error.response.data.message,
    //     customClass: {
    //       confirmButton: 'my-confirm-button',
    //     }
    //   });
    // }

    try {
      const payload = {
        subscriptionType: value,
        uid: uid,
      };
      const response = await axios.post(
        "https://api.v2.badskip.com/payments/subscribe",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": apiKey,
            customClass: {
              confirmButton: "my-confirm-button",
            },
          },
        }
      );

      window.location.href = response.data.url;
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Subscription Failed",
        text: error.response.data.message,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>BadSkip | Transparent & Fair Pricing </title>
      </Helmet>
      <NavBar />

      <section className="pricingSec1">
        <h2>
          Pricing
          <br />
          <span>(Buy Credits)</span>
        </h2>
        <Container>
          <h3>
            Transparent Pricing – No Surprises,
            <br />
            Just Results
          </h3>
          <p>
            At <span>BadSkip</span>, we believe in transparency. That’s why our
            pricing is straightforward and designed to fit businesses of all
            sizes.
          </p>
          <p>
            Whether you’re a small startup or a large corporation, our services
            are priced to provide maximum value without breaking the bank.
          </p>
        </Container>
      </section>

      {storedUserType === "callcenter" ? (
        <>
          <section className="pricingSec2">
            <h2>Our Pricing list</h2>
            <h4>One-Time Pricing:</h4>
            <Container>
              <Row>
                <Col md={4} sm={12}>
                  <div>
                    <h3>
                      0 to 9,999 <span>Credits</span>
                    </h3>
                    <p>
                      <span>3 cents </span> Per Credit
                    </p>
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div className="differ">
                    <h3>
                      10,000 to 19,999 <span>Credits</span>
                    </h3>
                    <p>
                      <span>2.5 cents </span> Per Credit
                    </p>
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div>
                    <h3>
                      20,000 and Above <span>Credits</span>
                    </h3>
                    <p>
                      <span>2.5 cents </span> Per Credit
                    </p>
                  </div>
                </Col>
              </Row>
              <HashLink to="/Pricing#pricingCheck">
                <button>Buy Credits</button>
              </HashLink>
            </Container>
          </section>

          <section className="pricingSec3">
            <h2>SUBSCRIPTION-BASED:</h2>
            <Container>
              <Row className="justify-content-center">
                <Col md={4} sm={12}>
                  <div>
                    <h3>
                      QUARTERLY <br /> 150,000<span> credits</span>
                    </h3>
                    <p>
                      $3,000<span> /Quarter</span>{" "}
                    </p>
                    <button onClick={() => CompletePurchase("QUARTERLY")}>
                      Choose
                    </button>
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div className="yearly">
                    <img src="images/best_value.svg" alt="pricing" />
                    <h3>
                      YEARLY <br /> 600,000<span> credits</span>
                    </h3>
                    <p>
                      $8,000<span> /Year</span>{" "}
                    </p>
                    <button onClick={() => CompletePurchase("YEARLY")}>
                      Choose
                    </button>
                  </div>
                </Col>
              </Row>
              <h5>
                We’re committed to delivering top-notch services at competitive
                prices,
                <br />
                so you can get the best results.
              </h5>
            </Container>
          </section>
        </>
      ) : (
        <>
          <section className="pricingSec2">
            <h2>Our Pricing list</h2>
            <h4>One-Time Pricing:</h4>
            <Container>
              <Row>
                <Col md={4} sm={12}>
                  <div>
                    <h3>
                      0 to 9,999 <span>Credits</span>
                    </h3>
                    <p>
                      <span>8.5 cents </span> Per Credit
                    </p>
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div className="differ">
                    <h3>
                      10,000 to 19,999 <span>Credits</span>
                    </h3>
                    <p>
                      <span>8 cents </span> Per Credit
                    </p>
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div>
                    <h3>
                      20,000 and Above <span>Credits</span>
                    </h3>
                    <p>
                      <span>7 cents </span> Per Credit
                    </p>
                  </div>
                </Col>
              </Row>
              <HashLink to="/Pricing#pricingCheck">
                <button>Buy Credits</button>
              </HashLink>
            </Container>
          </section>

          <section className="pricingSec3">
            <h2>SUBSCRIPTION-BASED:</h2>
            <Container>
              <Row className="justify-content-center">
                <Col md={4} sm={12}>
                  <div>
                    <h3>
                      QUARTERLY <br /> 150,000<span> credits</span>
                    </h3>
                    <p>
                      $6,000<span> /Quarter</span>{" "}
                    </p>
                    <button onClick={() => CompletePurchase("QUARTERLY")}>
                      Choose
                    </button>
                  </div>
                </Col>
                <Col md={4} sm={12}>
                  <div className="yearly">
                    <img src="images/best_value.svg" alt="pricing" />
                    <h3>
                      YEARLY <br /> 600,000<span> credits</span>
                    </h3>
                    <p>
                      $12,000<span> /Year</span>{" "}
                    </p>
                    <button onClick={() => CompletePurchase("YEARLY")}>
                      Choose
                    </button>
                  </div>
                </Col>
              </Row>
              <h5>
                We’re committed to delivering top-notch services at competitive
                prices,
                <br />
                so you can get the best results.
              </h5>
            </Container>
          </section>
        </>
      )}

      <section className="pricingSec4" id="pricingCheck">
        <h2>Add Credits</h2>
        <Container>
          <h4>Credits</h4>
          <input
            type="number"
            name="credits"
            onChange={handleCreditsChange}
            placeholder="10000"
            min={0}
          />
          <Col md={4} lg={4} className="d-flex couponform my-4 ms-auto">
            <input
              type="text"
              name="coupon"
              placeholder="coupon"
              onChange={(e) => setCouponCode(e.target.value)}
            />
            <button onClick={handleApplyCoupon}>Apply Coupon</button>
          </Col>
          <Row>
            <Col md={4} sm={12}>
              <div>
                <h3>Amount:</h3>
                <p>{credits}</p>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div>
                <h3>Credit Cost:</h3>
                <p>{(pricePerCredit * 100).toFixed(1)} cents /Credit</p>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div>
                <h3>Purchase Total:</h3>
                <p>
                  {discount > 0 ? (
                    <>
                      <del>${totalPrice} </del>
                      <br /> ${discountedPrice}{" "}
                    </>
                  ) : (
                    totalPrice
                  )}
                </p>
              </div>
            </Col>
          </Row>
          <div className="comp">
            <button onClick={handleCompletePurchase}>Complete Purchase</button>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Pricing;
