import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AdminLayout from "layouts/admin";
import SignIn from "SignIn";
import Home from "views/home/Home";

import Register from "./views/home/Register";
import SignInHome from "./views/home/SignIn";
import CallCenterRegistration from "./views/home/CallCenterRegistration";

import SubmitInquiry from "./views/home/SubmitInquiry";
import BuildVa from "./views/home/BuildVa";
import Blogs from "./views/home/Blogs";
import Pricing from "./views/home/Pricing";
import About from "./views/home/About";
import Account from "./views/home/Account";
import ListUploadHome from "./views/home/ListUpload";
import ListHistoryHome from "./views/home/ListHistory";
import ForgetPassword from "./views/home/ForgetPassword";
import ResetPassword from "./views/home/ResetPassword";
import PaymentHistory from "./views/home/PaymentHistory";
import ContactPricing from "./views/home/ContactPricing";
// import 'swiper/dist/css/swiper.min.css';
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./tailwind.css";
import "./views/home/App.css";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="admin/*" element={<AdminLayout />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/Home" element={<Home />} />
          {/* <Route path="/Register" element={<Register />} /> */}
          {/* <Route path="/SignIn" element={<SignInHome />} /> */}
          {/* <Route path="/CallCenterRegistration" element={<CallCenterRegistration />} /> */}
          <Route path="/SubmitInquiry" element={<SubmitInquiry />} />
          <Route path="/build-va-team" element={<BuildVa />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/ListUpload" element={<ListUploadHome />} />
          <Route path="/skiptracing" element={<ListHistoryHome />} />
          {/* <Route path="/ResetPassword" element={<ResetPassword />} /> */}
          {/* <Route path="/ForgetPassword" element={<ForgetPassword />} /> */}
          <Route path="/PaymentHistory" element={<PaymentHistory />} />
          <Route path="/ContactPricing" element={<ContactPricing />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
