import React from "react";
import Footer from "./Footer";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import NavBar from "./Navbarr";

function About() {
  return (
    <>
      <Helmet>
        <title>BadSkip | Meet the Team Behind the Mask</title>
      </Helmet>
      <NavBar />

      <section className="homeFourth about">
        <h2>About us</h2>

        <Container>
          <Row>
            <Col md={6} sm={12}>
              <p>
                At <span>BadSkip</span>, we don't just find people-we unearth
                them. Born out of a passion for precision and a little bit of
                stubbornness, we've made it our mission to track down the
                untraceable.
              </p>
              <p>
                What started as a simple quest to solve the puzzle of lost
                connections has evolved into a full-fledged operation,
                leveraging the latest technology and data analytics to bring you
                results that matter.
              </p>
              <p>
                Our journey began with a simple goal to bridge the gap between
                lost connections and opportunity.
              </p>
              <p>
                Over time, we specialize in the art of skip tracing - the
                precise process of locating individuals who have seemingly
                vanished. Leveraging cutting-edge technology and a deep
                understanding of data analytics, we bring you the information
                you need, right when you need it.
              </p>
              <p>
                <span>Our team?</span> We're a motley crew of data enthusiasts,
                tech whizzes, and customer service pros, all committed to one
                thing: finding the people you need to connect with.
              </p>
              <p>
                But it's not just about the data-we believe in doing things the
                right way, with a touch of integrity and a lot of dedication.
              </p>

              <p>
                Behind every successful lead, there's a story, and we're here to
                make sure it ends in a connection.
              </p>
            </Col>
            <Col md={6} sm={12}>
              <img
                src="images/people-taking-part-business-event.png"
                alt="about"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default About;
